<!--
 功能名称:千里眼平台--技师状态
 开发人:梁高权
 创建时间:2021/03/25
 最后修改时间:2021/03/25
-->
<template>
  <div>
    <vue-load @loadData="loadData">
      <div class="container">
        <tab-header :headerList="boss.techHeader" :currentTab="0" @changeTabPage="changeTabPage"></tab-header>
        <technician-type :typeList="techTypeList" :currentType="techType" @changeType="changeType"></technician-type>
        <technician-state-list :stateList="techStateList" :currentState="techState" @changeState="changeState"></technician-state-list>
        <technician-list :techList="groupingTechList"></technician-list>
        <technician-detail v-if="boss.showTechDetail" :techDetail="boss.techDetail"></technician-detail>
      </div>
    </vue-load>
  </div>
</template>

<script>
import table from '@/api/table'
import {mapState} from 'vuex'
let timeName = null
export default{
  name:"technician-state",
  data(){
    return{
      techStateList:[
        {id:'-1',label:'全部',sumNum:0},
        {id:'2',label:'上钟',sumNum:0},
        {id:'7',label:'预约',sumNum:0},
        {id:'3',label:'圈牌',sumNum:0},
        {id:'6',label:'选美',sumNum:0},
        {id:'4',label:'下班',sumNum:0},
        {id:'5',label:'休假',sumNum:0},
        {id:'1',label:'空闲',sumNum:0},
      ],
      techType:"全部",
      techState:'-1',
      techTypeList:new Object(),
      stateTotal:null,
      groupingTechList:null,
      //刷新间隔时间 15秒钟
      loadTime:15000
    }
  },
  computed:{
    ...mapState(['userStatus','boss'])
  },
  watch:{
    techType:{
      handler(){
        this.loadData(true)
      },
      deep:true
    },
    techState:{
      handler(){
        this.loadData(true)
      },
      deep:true
    },
    'userStatus.curs_node_id_2':{
      handler(){
        this.loadData(true)
      },
      deep:true
    }
  },
  components:{
    'vue-load':() => import('@/components/vue-load'),
    'tab-header':() => import('@/components/tab-header'),
    'technician-type':() => import('./technician-type'),
    'technician-state-list':() => import('./technician-state-list'),
    'technician-list':() => import('./technician-list'),
    'technician-detail':() => import('./technician-detail')
  },
  methods:{
    changeTabPage(index){
      this.$emit("changeTab",index)
      this.currentTab = index
    },
    /* 获取技师类型*/
    getTechnicianTypeList(loading){
      return new Promise((resolve,reject) =>{
        table.getTechnicianTypeList().then(res =>{
          let {techType} = this
          let typeList = res.data
          this.stateTotal = res.data[techType]
          // var arr = [];
          // for (let i in typeList) {
          //   if(i=='全部'){arr.unshift(i)}else{arr.push(i)}
          //   // arr.unshift(i)
          // }

          // console.log(JSON.parse(JSON.stringify(typeList, arr)))
          // console.log(arr)
          // console.log(typeList)
          this.techTypeList = typeList
          this.getStateSumNum()
          resolve()
        }).catch(err =>{
          Object.assign(this.$data, this.$options.data());
          console.log("err",err.msg)
          reject()
        })
      })
    },
    /*获取技师列表*/
    getTechnicianList(loading){
      let {techType,techState} = this
      let type = techType=="全部"?'-1':techType
      let params = {
        techType:type,
        techState
      }
      return new Promise((resolve,reject) =>{
        table.getTechnicianList(params).then(res =>{
          let techList = res.data.technian_info
          this.groupingTechList = techList
          this.stateTotal = res.data.technian_total
          resolve()
        }).catch(err =>{
          console.log('err',err.msg)
          reject()
        })
      })
    },
    /* 统计各状态人数*/
    getStateSumNum(){
      let {techStateList,stateTotal,groupingTechList} = this
      for(let state of techStateList){
        for(let key in stateTotal){
          if(state.id == key){
            state.sumNum = stateTotal[key]
          }
          if(state.id == '-1'){state.sumNum = stateTotal['total']}
        }
      }
    },
    /* 切换技师类型*/
    changeType(type){
      this.techType = type
    },
    /* 切换技师状态*/
    changeState(state){
      this.techState = state
    },
    /*请求数据*/
    loadData(loading){
      this.getTechnicianTypeList(loading).then(() =>{
        this.getTechnicianList(loading)
      })
    }
  },
  created(){
    this.loadData(true)
    // let {loadTime} = this
    //       if(this.timer){
    //   clearInterval(this.timer)
    //       }else{
    //   this.timeName = setInterval(()=>{
    //     this.loadData()
    //           },loadTime)
    //       }
  },
  destroyed(){
    clearInterval(this.timeName)
    this.timeName = null
  }
}
</script>

<style lang="scss" type="text/scss" scoped >
@import "~@/assets/style/public.scss";
.container{
    flex:1;
    display:flex;
    flex-direction:column;
    height:calc(100vh - 100px);
    overflow:hidden;
}
</style>
